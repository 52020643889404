<template>
   <div class="dropdown topbar-head-dropdown ms-1 header-item">
      <button
         type="button"
         class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
         id="page-header-notifications-dropdown"
         data-bs-toggle="dropdown"
         aria-haspopup="true"
         aria-expanded="false"
         >
      <i class="bx bx-bell fs-22"></i>
      <span
         v-if="unreadNotifications.length"
         class="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger"
         >
            {{ unreadNotifications.length }}</span
         >
      </button>
      <div
         class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
         aria-labelledby="page-header-notifications-dropdown"
         >
         <div class="dropdown-head bg-primary bg-pattern rounded-top">
            <div class="p-3">
               <div class="row align-items-center">
                  <div class="col">
                     <h6 class="m-0 fs-16 fw-semibold text-white">
                        Notifications
                     </h6>
                  </div>
                  <div class="col-auto dropdown-tabs">
                     <span class="badge badge-soft-light fs-13">
                     {{ unreadNotifications.length }} New</span
                        >
                  </div>
               </div>
            </div>
         </div>
         <div>
            <SimpleBar data-simplebar style="max-height: 300px" class="">
               <div  v-for="(notif, nIndex) in activeNotifs" :key="nIndex" 
               class="text-reset border-bottom notification-item 
               d-block dropdown-item position-relative" :class="{'bg-light': !notif.is_seen }" >
                  <div class="d-flex" >
                     <div class="flex-1">
                        <a @click.prevent="navigateTo(notif)" href="#!" class="stretched-link" >
                           <h6 class="mt-0 mb-1 fs-13 fw-semibold">
                              {{notif.title}}
                           </h6>
                        </a>
                        <div class="fs-13 text-muted">
                           <p class="mb-1">
                              {{notif.content}}
                           </p>
                        </div>
                        <p class="mb-0 fs-11 fw-medium text-uppercase text-muted">
                           <span><i class="mdi mdi-clock-outline"></i> {{$filters.date_ago(notif.created_at)}}</span>
                        </p>
                     </div>
                  </div>
               </div>
               <div class="my-3 text-center">
                  <router-link
                     to="/notifications"
                     class="btn btn-soft-success"
                     >
                     View all
                     <i class="ri-arrow-right-line align-middle"></i>
                  </router-link>
               </div>
            </SimpleBar>
         </div>
      </div>
   </div>
</template>
<script>
   import { SimpleBar } from "simplebar-vue3";
   
   export default {
       components: { SimpleBar },
       computed: {
         notifications(){
            return this.$store.state.notification.list
         },
         unreadNotifications() {
            return this.notifications.filter((notif) => notif.is_seen == 0);
         },
         readNotifications() {
            return this.notifications.filter((notif) => notif.is_seen == 1 );
         },
         activeNotifs() {
            return this.unreadNotifications.length > 1
            ? [...this.unreadNotifications.slice(0,10), ...this.readNotifications.slice(0,5)]
            : this.notifications.slice(0, 10);
         },
       },
       methods: {
           navigateTo(notif) {
               if (!notif.is_read) {
                   this.$store.dispatch("notification/markAsRead", notif.id);
               }
               this.$router.push(notif.url).catch(() => {});
           },
       },
       created() {
         this.$store.dispatch("notification/fetchNotifications");
       },
   };
</script>